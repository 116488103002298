import axios from '@/axios';
import { ProductCategoryAction } from '@/store/actionTypes';
import { ProductCategoryMutation } from '@/store/mutationTypes';

export default {
  [ProductCategoryAction.add]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post('/product-categories', item)
        .then(({ data }) => {
          commit(
            ProductCategoryMutation.ADD,
            Object.assign(item, { id: data.id })
          );
          resolve(data);
        })
        .catch(reject);
    });
  },
  [ProductCategoryAction.fetch]({ commit }) {
    return new Promise((resolve, reject) => {
      return axios
        .get('/product-categories')
        .then(({ data }) => {
          commit(ProductCategoryMutation.SET, data);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [ProductCategoryAction.update]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/product-categories/${item.id}`, item)
        .then(({ data }) => {
          commit(ProductCategoryMutation.UPDATE, data);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [ProductCategoryAction.remove]({ commit }, id) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(`/product-categories/${id}`)
        .then(({ data }) => {
          commit(ProductCategoryMutation.REMOVE, id);
          resolve(data);
        })
        .catch(reject);
    });
  },
};
